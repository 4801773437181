.listheader {
    display: grid;
    grid-template-columns: 1fr 4fr repeat(4, 1fr);
    color: var(--blue);
    align-items: center;
    transition: .2s ease-in;
}

.listheader {
    background: none !important;
}

.name {
    grid-column-start: 2;
}

.date {
    grid-column-start: 5;
    text-align: center;
}

.size {
    grid-column-start: 6;
    text-align: center;
}